<template>
  <div>
    <v-card class="pa-3">
      <v-progress-linear
        :active="isLoading"
        :indeterminate="isLoading"
        :height="1"
        class="pa-0 ma-0"
      /> 
    </v-card>

    <v-card v-if="reason && reason.length" class="pa-3">
      <v-layout row>
        <v-flex xs12>
          <div class="title text--darken-1" v-text="getRemovalInfo.title"></div>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs3>
          <div class="mb-3">
            <v-avatar v-if="getRemovalInfo.user_photo" size="65px">
              <img :src="getRemovalInfo.user_photo" alt="">
            </v-avatar>
            <v-avatar v-else size="65px">
              <v-icon class="no-avatar-icon">
                mdi-account
              </v-icon>
            </v-avatar>
          </div>

          <div class="subheading text--darken-1 mb-3">
            <router-link
              :to="`/moderation/user_list/user/${getRemovalInfo.user_id}`"
              class="blue-grey--text bold link-hover break"
            >
              {{ getRemovalInfo.username }}
            </router-link>
          </div>

          <div class="mt-3 mb-4">
            <span
              v-for="(tags, index) in getRemovalInfo.tags"
              :key="index"
              class="font-size-15 bold deep-orange--text mr-2"
            >
              {{ tags.title }}
            </span>
          </div>

          <div class="mb-4">
            <div class="mb-2">
              <v-icon class="blue-grey--text mr-2">
                mode_comment
              </v-icon>
              <b class="mr-1" v-text="getRemovalInfo.comments_count"></b>
              Comments
            </div>
            <div class="mb-2">
              <v-icon class="blue-grey--text mr-2">
                photo
              </v-icon>
              <b class="mr-1" v-text="getRemovalInfo.posts_count"></b>
              Posts
            </div>
            <div class="mb-2">
              <v-icon class="blue-grey--text mr-2">
                thumb_up
              </v-icon>
              <b class="mr-1" v-text="getRemovalInfo.upvotes"></b>
              Upvotes
            </div>
            <div class="mb-2">
              <v-icon class="blue-grey--text mr-2">
                thumb_down
              </v-icon>
              <b class="mr-1" v-text="getRemovalInfo.downvotes"></b>
              Downvotes
            </div>
          </div>

          <div class="headline">
            Reports:
            <span class="bold" v-text="getRemovalInfo.reports_count"></span>
          </div>
        </v-flex>

        <v-flex xs9>
          <v-layout row justify-space-between>
            <v-flex xs7 class="break">
              {{ getRemovalInfo.reason_to_remove }}
            </v-flex>
            <v-flex xs3 class="text-xs-right">
              {{ dateFormat(getRemovalInfo.created_at) }} at
              {{ timeFormat(getRemovalInfo.created_at) }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex xs3>
          <div class="text-xs-left ">
            <v-btn
              color="error"
              class="ma-0"
              :disabled="postDeleted || getRemovalInfo.deleted_post !== null"
              @click="openDeletePostModal(getRemovalInfo.id)"
            >
              <span v-if="postDeleted">Deleted</span>
              <span v-else>Delete</span>
            </v-btn>
          </div>
        </v-flex>
        <v-flex xs9 class="text-xs-right">
          <v-btn flat class="blue-grey ma-0" @click="returnBack()">
            <v-icon left dark class="font-size-20">
              arrow_back
            </v-icon>
            Back
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>
    <delete-post-modal
      v-model="deletePostModal"
      :post-id="postId"
      :reason="reason"
      @deleteCurrentPost="deleteCurrentPost"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import DeletePostModal from "../../../../../components/Moderation/PostList/DeletePostModal"
import moment from "moment"
import { getReasons } from "../../../../../api/reason"
export default {
  name: "MonRemovalRequestPost",
  components: {
    DeletePostModal
  },
  data() {
    return {
      isLoading: false,
      postDeleted: false,
      deletePostModal: false,
      prevRoute: null,
      reason: [],
      postId: ""
    }
  },
  computed: {
    ...mapGetters(["getRemovalInfo"])
  },
  async created() {
    await this.getInfoAboutPost(this.$route.params.id)
    await this.getReasonBlockUser()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  methods: {
    deleteCurrentPost(id) {
      this.postDeleted = id
    },
    openDeletePostModal(id) {
      this.postId = id
      this.deletePostModal = true
    },
    async getReasonBlockUser() {
      this.isLoading = true
      this.reason = await getReasons()
        .then(response => {
          return response.data.data
        })
        .then(data =>
          data.map(reason => {
            return reason
          })
        )
        .catch(this.$notificationHandleResponseError)
        .finally(() => (this.isLoading = false))
    },
    ...mapActions(["getRemovalDetails"]),
    getInfoAboutPost(id) {
      this.$store.dispatch("getRemovalDetails", id)
    },
    timeFormat(date) {
      return moment(date).format("HH:mm")
    },
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY")
    },
    returnBack() {
      if (this.prevRoute && this.prevRoute.fullPath === "/") {
        this.$router.push({ name: "removal_posts" })
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>
<style scoped>
.link-hover:hover {
  color: #fff !important;
  text-decoration: none !important;
}
.date {
  min-width: 120px;
}
.text-end-center {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
</style>
